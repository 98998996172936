import { hc } from "hono/client";
import useTheme from "../../../js/composables/useTheme";
import { AppType } from "./index";

const baseUrl = useTheme().IS_STAGING
  ? "https://shopify-api-staging.dutchpet.workers.dev"
  : "https://shopify-api.dutchpet.workers.dev";

// Define the client type with proper route structure
type ShopifyClient = {
  customers: {
    subscribe: {
      $post: (options: {
        json: { email: string; phoneNumber?: string };
      }) => Promise<Response>;
    };
    [":customer_id"]: {
      tags: {
        $put: (options: {
          param: { customer_id: string };
          json: { customer_email: string; tags: string };
        }) => Promise<any>;
      };
      refer: {
        $post: (options: {
          param: { customer_id: string };
          json?: any;
        }) => Promise<{ status: number; ok: boolean }>;
      };
    };
  };
};

// Create the client with the proper type
export const ShopifyWorker = hc<AppType>(baseUrl) as unknown as ShopifyClient;
